import React from 'react';
import styled from 'styled-components';

import AboutBox from '../../AboutBox/AboutBox';
import TagList from '../../TagList/TagList';

const SidebarStyled = styled.aside`
    display: flex;
    flex-direction: column;
    width: 20%;

    @media ${props => props.theme.media.tabletL} {
        width: 100%;
    }
`;


const sidebar = (props) => {
    return (
        <SidebarStyled>
            <AboutBox />
            <TagList />
        </SidebarStyled>
    )
}


export default sidebar;