import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import LogoImage from '../../../images/logo.png'


const HeaderStyled = styled.div`
    background: ${props => props.theme.colors.secondaryAccent};
    color: ${props => props.theme.colors.dark};
    width: 100%;
    height: ${props => props.theme.sizes.headerHeight};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px ${props => props.theme.sizes.contentMargin};

    @media ${props => props.theme.media.tabletS} {
        padding: 10px ${props => props.theme.sizes.resContentMargin};
    }
`;

const Logo = styled.img`
    max-height: ${props => props.theme.sizes.headerHeight};

    @media ${props => props.theme.media.tabletS} {
        max-width: 500px;
    }
    @media ${props => props.theme.media.mobileL} {
        max-width: 300px;
    }
`;


const Header = (props) => {
    return (
        <HeaderStyled>
            <Link to='/'><Logo src={LogoImage}></Logo></Link>
        </HeaderStyled>
    )
}


export default Header;