export const theme = {
    colors: {
        primaryAccent: '#593F62', //purple
        secondaryAccent: '#95B092', //green
        light: '#F7F3E3', //white
        medium: '#3F4045', //grey
        dark: '#080708', //black
        opacityLight: 'rgba(255, 255, 255, 0.5 )' //white semi-transparent
    },
    fonts: {
        primary: '"Roboto", Sans-Serif'
    },
    sizes: {
        contentWidth: '70%',
        contentMargin: '15%',
        resContentWidth: '90%',
        resContentMargin: '5%',
        headerHeight: '100px',
        navBarHeight: '40px',
        combinedHeaderHeight: '150px',
        footerHeight: '80px'
    },
    media: {
        tabletL: '(max-width: 1300px)',
        tabletS: '(max-width: 1000px)',
        mobileL: '(max-width: 500px)',
        mobileS: '(max-width: 320px)'
    }

};