import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../../styles/theme';
import { GlobalStyle } from '../../styles/globalStyle';
import PropTypes from 'prop-types';
import '../../styles/prismTheme.css'

import Helmet from '../../components/utility/CustomHelmet/CustomHelmet';
import Header from '../../components/UI/Header/Header';
import Footer from '../../components/UI/Footer/Footer';
import SideBar from '../../components/UI/Sidebar/Sidebar';

const PageWrapper = styled.div`
  position: relative;
  min-height: calc(100vh - ${props => props.theme.sizes.footerHeight});
  padding-bottom: 20px;
`;

const ContentWrapper = styled.div`
  width: ${props => props.theme.sizes.contentWidth};
  margin: 30px auto;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.media.tabletL} {
        flex-direction: column;
    }
  @media ${props => props.theme.media.tabletS} {
        width: ${props => props.theme.sizes.resContentWidth};
    }
`;

const MainColumn = styled.main`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 40px;

    @media ${props => props.theme.media.tabletL} {
        width: 100%;
        align-items: center;
        padding-right: 0;
    }
`;

class Layout extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <PageWrapper>
                    <GlobalStyle />
                    <Helmet
                        title={this.props.title}
                        description={this.props.description}
                        image={this.props.image}
                        url={this.props.url}
                        type={this.props.type}
                    />
                    <Header />
                    <ContentWrapper>
                        <MainColumn>
                            {this.props.children}
                        </MainColumn>
                        <SideBar />
                    </ContentWrapper>
                    <Footer />
                </PageWrapper>
            </ThemeProvider>
        )
    }
}

Layout.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default Layout;