import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Tag from '../Tag/Tag';
import MinorHeading from '../UI/MinorHeading/MinorHeading';

const TagListStyled = styled.div`
    width: 100%;
    padding: 20px;
`;

// const ContactInfo = styled.div`
//     width: 50%;
//     padding-right: 10px;
// `;

// const FooterText = styled.p`
//     font-size: 1.1em;
//     color: #fff;
// `;

// const FooterHeading = styled(FooterText)`
//     font-size: 1.1em;
//     font-weight: 700;
//     color: #fff;
// `;


const TagList = ({ data }) => {
    const tagsArray = data.allMarkdownRemark.group
    const sortedTagsArray = tagsArray.sort(function(a, b){
        return b.totalCount-a.totalCount
    })
    const List = sortedTagsArray.map(tag => {
        return (
            <Tag  key={tag.fieldValue} slug={'/tags/' + tag.fieldValue}>{tag.fieldValue}</Tag>
        )
    })
    return (
        <TagListStyled>
            <MinorHeading>Popular tags</MinorHeading>
            {List}
        </TagListStyled>
        
        
    )
}


export default props => (
    <StaticQuery
      query={graphql`
        query {
            allMarkdownRemark(limit: 2000) {
                group(field: frontmatter___tags) {
                  fieldValue
                  totalCount
                }
              }
        }
      `}
      render={data => <TagList data={data} {...props} />}
    />
  );