import React from 'react';
import styled from 'styled-components';

const MinorHeadingStyled = styled.div`
    font-size: 1.5em;
    color: ${props => !props.textColor ? props.theme.colors.primaryAccent : props.textColor};
    margin: 10px 0;
    display: block;
    text-align: ${props => !props.textAlign ? 'left' : props.textAlign};
`;


const minorHeading = (props) => {
    return (
        <MinorHeadingStyled {...props}>
            {props.children}
        </MinorHeadingStyled>
    )
}


export default minorHeading;