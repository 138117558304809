import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const TagStyled = styled(Link)`
    font-size: 0.8em;
    background-color: ${props => props.theme.colors.secondaryAccent};
    border-radius: 5px;
    display: inline-block;
    padding: 5px;
    margin: 10px 10px 10px 0;

    :hover {
        background-color: ${props => props.theme.colors.primaryAccent};
        color: ${props => props.theme.colors.light};
    }
`;


const tag = (props) => {
    return (
        <TagStyled to={props.slug.toLowerCase()}>
            {props.children}
        </TagStyled>
    )
}


export default tag;