import React from 'react';
import styled from 'styled-components';

const FooterStyled = styled.div`
    width: 100%;
    height: ${props => props.theme.sizes.footerHeight};
    background-color: ${props => props.theme.colors.primaryAccent};
    position: absolute;
    bottom: -${props => props.theme.sizes.footerHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;;
    color: #fff;
    padding: 10px ${props => props.theme.sizes.contentMargin};

    @media ${props => props.theme.media.tabletS} {
        padding: 10px ${props => props.theme.sizes.resContentMargin};
    }
`;


const Footer = (props) => {
return (
    <FooterStyled>
            Powered by GatsbyJS
        </FooterStyled>
)
}


export default Footer;