import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const CustomHelmet = (props) => {
        const image = 'https://www.frontendstumbles.com/' + props.image
        return (
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1.0" />

                <title>{props.title}</title>
                <meta name="description" content={props.description} />

                <meta property="og:title" content={props.title} />
                <meta property="og:description" content={props.description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={props.url} />
                <meta property="og:type" content={props.type}/>


                <meta name="twitter:title" content={props.title} />
                <meta name="twitter:description" content={props.description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

        )
}


CustomHelmet.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default CustomHelmet;