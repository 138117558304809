import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from "gatsby";

const AboutBoxStyled = styled.div`
    background-color: ${props => props.theme.colors.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;

    @media ${props => props.theme.media.tabletL} {
        flex-direction: row;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const Paragraph = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colors.medium};
    text-align: center;
    padding: 10px 0;

    @media ${props => props.theme.media.tabletL} {
        text-align: left;
    }
`;

const TextWrapper = styled.div`
    padding: 15px 5px 5px 5px;

    @media ${props => props.theme.media.tabletL} {
        padding: 5px 5px 5px 15px;
    }
`;

const ImgStyled = styled(Img)`
    width: 100%;

    @media ${props => props.theme.media.tabletL} {
        height: 100%;
        max-height: 250px;
        width: inherit;
    }
`;


const AboutBox = ({ data }) => {
    return (
        <AboutBoxStyled>
            <ImgStyled fluid={data.file.childImageSharp.fluid} />
            <TextWrapper>
                <Paragraph><strong>Hi, I'm Issy.</strong></Paragraph>
                <Paragraph>I'm a front-end developer (and ex-researcher<span role="img" aria-label="chicken">🐔</span>) with a love for learning new tools and tech. I can usually be found making websites, wading through pages of confusing documentation, or taking hundreds of poor-quality photos of my dogs. </Paragraph>
                <Paragraph><strong>GitHub:</strong> <a href='https://github.com/issydennis'>@issydennis</a></Paragraph>
            </TextWrapper>

        </AboutBoxStyled>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
          query {
            file (sourceInstanceName: {eq: "images"} name: {eq: "about"}) {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        `}
        render={data => <AboutBox data={data} {...props} />}
    />
);